.hero {
  background: url('/public/render.jpg') center;
  background-size: cover;
  margin-top: 69px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  font-size: 2.5rem;
  text-shadow: 1px 1px 4px #000000bd;
}
.subtitle {
  text-align: center;
  font-size: 1.3rem;
  text-shadow: 1px 1px 3px #000000bd;
}

.hero-title {
  text-align: center;
  font-size: 3rem;
  text-shadow: 1px 1px 4px #000000bd;
}
.hero-subtitle {
  text-align: center;
  font-size: 1.8rem;
  text-shadow: 1px 1px 3px #000000bd;
}


.list-unstyled {
  padding-left: 0;
  list-style: none;
}
li.nav-item {
  padding: 0 5px;
}
.footer-link {
  color: #777;
  transition: all 0.3s;
}